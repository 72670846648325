<template>
  <!-- Düğme bileşeni -->
  <div class="h-28 w-28 max-lg:h-24 max-lg:w-24">
    <transition name="fade">
      <button
        :data-v-step="step"
        v-show="!disabled"
        @mousedown="isHoldAndSpeak && startRecording()"
        @mouseup="isHoldAndSpeak && stopRecording()"
        @click="!isHoldAndSpeak && activeProccess()"
        :disabled="disabled"
        :class="['text-lg custom-btn ', buttonClass]"
      >
        <!-- Mikrofon ikonu -->
        <span v-if="step == 0 && !disabled && !activeRecord">
          <i class="fa-solid fa-microphone text-xl"></i>
        </span>

        <!-- Ses kaydedilirken gösterilen canvas -->
        <span v-else-if="step == 0 && !disabled && activeRecord">
          <canvas ref="canvas" height="100%" width="100%"></canvas>
        </span>

        <!-- İşlem devam ederken gösterilen dönen ikon -->
        <span v-else-if="!disabled && step == 1">
          <i class="fa-solid fa-spinner fa-spin text-4xl"></i>
        </span>
      </button>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "action-btn",
  props: ["disabled"],

  data() {
    return {
      step: 0,
      activeRecord: false,
      audioContext: null,
      analyser: null,
      dataArray: null,
      animationFrameId: null,
      isButtonPressed: false,
      permissionGranted: false,
      randomFactors: null,
      speeds: null
    };
  },

  computed: {
    ...mapState(["micActiveType"]),
    buttonClass() {
      return this.activeRecord ? "pulse circle bg-white/10" : "";
    },
    isHoldAndSpeak() {
      return this.micActiveType === 2;
    },
  },

  mounted() {
    window.addEventListener("mouseup", this.stopRecording);
  },

  beforeDestroy() {
    window.removeEventListener("mouseup", this.stopRecording);
    this.cleanupAudio();
  },

  methods: {
    reset() {
      this.step = 0;
      this.activeRecord = false;
      this.cleanupAudio();
    },
    cleanupAudio() {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
      }
      if (this.audioContext) {
        this.audioContext.close();
      }
      this.audioContext = null;
      this.analyser = null;
      this.dataArray = null;
      this.randomFactors = null;
      this.speeds = null;
    },

    async requestMicrophonePermission() {
      if (!this.permissionGranted) {
        try {
          await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          this.permissionGranted = true;
        } catch (error) {
          // Mikrofon izni reddedildi hatasını emit et
          this.$emit("permissionDenied", this.$t("components.recordButton.microphonePermission"));
        }
      }
    },

    async startRecordingProcess() {
      if(this.activeRecord || this.step != 0) return 
      try {
        this.$emit("isRecord", true);
        this.activeRecord = true;
        await this.requestMicrophonePermission();
        
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: true, 
          video: false 
        });
        
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        source.connect(this.analyser);
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyseAudio();
      } catch (error) {
        console.error("Error starting recording:", error);
        this.reset();
      }
    },

    stopRecordingProcess() {
      this.$emit("isRecord", false);
      this.activeRecord = false;
      this.cleanupAudio();
      this.step++;
    },

    async startRecording() {
      if (!this.activeRecord && this.isHoldAndSpeak) {
        await this.startRecordingProcess();
      }
    },

    stopRecording() {
      if (this.activeRecord && this.isHoldAndSpeak) {
        this.stopRecordingProcess();
      }
    },

    analyseAudio() {
      if (!this.$refs.canvas || !this.analyser) return;

      this.analyser.getByteFrequencyData(this.dataArray);
      const volume = this.dataArray.reduce((a, b) => a + b) / this.dataArray.length;
      
      this.drawOnCanvas(volume);
      this.animationFrameId = requestAnimationFrame(this.analyseAudio.bind(this));
    },

    drawOnCanvas(volume) {
      const canvas = this.$refs.canvas;
      if (!canvas) return;
      
      const ctx = canvas.getContext("2d");

      // Canvas'ı temizle
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Canvas genişliğini ve yüksekliğini aynı yap
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      // Kutu özelliklerini tanımla
      const boxCount = 5;
      const boxSize = 8;
      const gutter = 6;
      const baseY = canvas.height / 2;

      // Her kutu için maksimum yükseklikleri tanımla
      const maxHeights = [
        canvas.height * 0.6,
        canvas.height * 1,
        canvas.height * 0.7,
        canvas.height * 1,
        canvas.height * 0.6,
      ];

      // Her kutu için rastgele bir yükseklik belirle
      const scaleY = [];
      this.randomFactors = this.randomFactors || Array(boxCount).fill(1);
      this.speeds = this.speeds || Array(boxCount).fill(0);
      
      for (let i = 0; i < boxCount; i++) {
        this.randomFactors[i] = 0.5 + Math.random() * 0.5;
        const targetHeight = maxHeights[i] * this.randomFactors[i] * (volume / 100);
        
        if (this.speeds[i] < targetHeight) {
          this.speeds[i] += 1.8; // Yükselme hızını belirle
        } else {
          this.speeds[i] -= 0.7; // Düşme hızını belirle
        }
        scaleY[i] = this.speeds[i];
      }

      // Tüm kutuların toplam genişliğini hesapla
      const totalBoxWidth = boxCount * boxSize + (boxCount - 1) * gutter;

      // Kutuları çiz
      const startX = (canvas.width - totalBoxWidth) / 2;
      for (let i = 0; i < boxCount; i++) {
        const x = startX + i * (boxSize + gutter);
        const height = Math.min(maxHeights[i], scaleY[i]);

        ctx.fillStyle = "white";
        ctx.fillRect(x, baseY - height / 2, boxSize, height);
      }
    },

    async activeProccess() {
      await this.requestMicrophonePermission();

      if (!this.activeRecord && !this.isHoldAndSpeak) {
        await this.startRecordingProcess();
      } else if (this.activeRecord) {
        this.stopRecordingProcess();
      }
    },
  },
};
</script>

<style scoped>
.custom-btn {
  @apply h-28 w-28 max-lg:h-24 max-lg:w-24 border border-white text-white transition-all ease-out rounded-full flex items-center justify-center mx-auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.circle {
  border-radius: 50%;
  box-shadow: 0px 0px 3px 3px #0000001a;
}
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>