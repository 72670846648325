import axios from 'axios';
import store from '../store';
import router from '../router';

const instance = axios.create();

instance.interceptors.request.use(
    config => {
        const token = store.state.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        const currentRoute = router.currentRoute;
        const lang = currentRoute.params.lang;
        if (lang) {
            config.headers['accept-language'] = lang;
        }

        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    response => response,
    async error => {
        // If response is 401, redirect user to homepage
        if (error.response && error.response.status === 401) {
            store.commit('signOut');
            router.push('/');
        }
        return Promise.reject(error);
    }
);

export default instance;