<template>
  <div>
    <img
      v-show="this.gifActive === false"
      src="@/assets/video/asistans.png"
      alt=""
      class="w-44 h-44 mt-2 md:mt-0 sm:w-64 sm:h-64 rounded-full "
    />
    <img
      v-show="this.gifActive === true"
      src="@/assets/video/assistants.gif"
      alt=""
      class="w-44 h-44 mt-2 md:mt-0 sm:w-64 sm:h-64 rounded-full "
    />
    
    <!-- Preload container (hidden) -->
    <div class="preload">
      <img src="@/assets/video/asistans.png" alt="">
      <img src="@/assets/video/assistants.gif" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "asistansPage",
  props: ["gifActive"],
  mounted() {
    // Alternative JavaScript preloading method
    this.preloadMedia([
      require('@/assets/video/asistans.png'),
      require('@/assets/video/assistants.gif')
    ]);
  },
  methods: {
    preloadMedia(urls) {
      urls.forEach(url => {
        const img = new Image();
        img.src = url;
      });
    }
  }
};
</script>

<style scoped>
.preload {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}
</style>


