import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

//error Page
import interviewNotFound from "../views/errorPage/interviewError.vue";

import endOfProcess from "../views/endOfProcess/index.vue";
import thanksPage from "../views/endOfProcess/thanksPage.vue";
//interview
import firstPage from "../views/preInterview/first-step/index.vue";
import secondPage from "../views/preInterview/second-step-start/index.vue";
import thirdPage from "../views/preInterview/third-step-interview/index.vue";
import fourPage from "../views/preInterview/four-step-assistan/index.vue";
import fivePage from "../views/preInterview/five-step-go-list/index";

import thanksSpeech from "../views/preInterview/thanksSpeech.vue";
import answerList from "../views/preInterview/answerList.vue";

import interviewDateFirst from "../views/interview/index.vue";
import interviewDatePage from "../views/interview/datePage/index.vue";

// assessment
import asseestmentStart from "../views/assesstment/beginA/index.vue";
import examLayout from "../views/assesstment/examLayout/index.vue";
import personalInformation from "../views/personalInformation/index.vue";
import resumeStorageTime from "../views/resumeStorageTime/index.vue";
import interviewSuccess from "../views/interview/datePage/components/success.vue";

// reference
import referencePage from "../views/reference/index.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    beforeEnter: () => {
      window.location.href = 'https://cvus.app/';
    }
  },
  {
    path: "/setup/pre/interview/:lang",
    name: "firstPage",
    component: firstPage,
  },
  {
    path: "/interview/inform",
    name: "interviewInformation",
    component: secondPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/pre/interview",
    name: "questionAndAnswer",
    component: thirdPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/candidate/question",
    name: "candidateQuestion",
    component: fourPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/complate/answer/list",
    name: "complateAnswerList",
    component: fivePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/answer/list",
    name: "answerList",
    component: answerList,
    meta: { requiresAuth: true },
  },
  {
    path: "/interview/thanks/speech",
    name: "thanksSpeech",
    component: thanksSpeech,
    meta: { requiresAuth: true },
  },
  {
    path: "/interview/setup/pick-date",
    name: "PickInterviewDate",
    component: interviewDatePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/interview/setup/:lang/:token",
    name: "SetupInterview",
    component: interviewDateFirst,
  },

  // assessment
  {
    path: "/assessment",
    name: "assessment",
    component: asseestmentStart,
  },
  {
    path: "/assessment/exam",
    name: "exam",
    component: examLayout,
  },

  {
    path: "/personal/information/:lang/:token",
    name: "personalInformation",
    component: personalInformation,
  },
  {
    path: "/resume/storage/time/:token/:isVerify",
    name: "resumeStorageTime",
    component: resumeStorageTime,
  },
  {
    path: "/interview/success",
    name: "interviewSuccess",
    component: interviewSuccess,
  },
  {
    path: "/reference/:lang/:token",
    name: "reference",
    component: referencePage,
  },
  // error pages
  {
    path: "/interview/not/found",
    name: "interviewNotFound",
    component: interviewNotFound,
  },
  
  {
    path: "/thank-you",
    name: "thanksPage",
    component: thanksPage,
  },
  {
    path: "/end",
    name: "end",
    component: endOfProcess,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.state.isPreliminaryInterview
  ) {
    next("/end");
  } else {
    next();
  }
});

export default router;
