<template>
  <div></div>
</template>
<script>
import Recorder from "js-audio-recorder";
import { meetingInterview } from "@/networking/urlmanager";
export default {
  name: "sound-question",
  data() {
    return {
      recorder: new Recorder({
        sampleBits: 16,
        sampleRate: 44100,
        numChannels: 1,
      }),
      load: false,
      isRecording: false,
    };
  },

  methods: {
    startRecording() {
      navigator.mediaDevices;
      if (this.isRecording) return;
      this.recorder.start().then(() => {
        this.isRecording = true;
      });
    },
    async stopRecording() {
      if (!this.isRecording) return;

      await this.recorder.stop();

      const audioBlob = this.recorder.getWAVBlob();
      this.isRecording = false;
      this.load = true;
      this.$emit("load", true);
      await this.processRecording(audioBlob);
    },
    async processRecording(blob) {
      try {
        const formData = new FormData();
        formData.append("file", blob, "audio.wav");
        formData.append("token", this.$store.state.token);

        let result = await this.axios.post(meetingInterview.candidateQuesiton, formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if(result.data.data.isType){
          const mp3Array = result?.data?.data?.url?.buffer;
          const uint8Array = new Uint8Array(mp3Array?.data);
          const blob = new Blob([uint8Array], { type: "audio/mp3" });
          let url = URL.createObjectURL(blob);

          this.$emit("changeAnswer", {
            name:result.data.data.answer,
            url:url
          });

          if(result.data.data.question && result.data.data.answer){
            this.$store.commit("addCandidateQuestionsAndAnswers", {
              question:result.data.data.question,
              answer:result.data.data.answer
            });
          }
          
        }
        else {
          this.$emit("noSoundDetected", {
            noSoundDetected: false,
            isLanguageError:  result.data.data.languageError,
          });
        }
        this.$emit("load", false);

        this.load = false;
      } catch (error) {
        this.authController(error);
        this.load = false;
        this.$emit("noSoundDetected", true);
        this.$emit("load", false);

      }
    },
  },
};
</script>
