<template>
  <div class=" transition-all ease-in-out">
    <div class="w-full h-full justify-center py-2">
      <startBtn v-if="!load" />
      <loadingItem v-else :load="load" />
    </div>
    <tour :steps="steps" v-if="!load" />
  </div>
</template>

<script>
import tour from "@/components/tour.vue";

// local component
import loadingItem from "./components/loading.vue";
import startBtn from "./components/start.vue";
import axios from "axios";
import moment from 'moment'

// networking
import { meetingInterview } from "@/networking/urlmanager";
export default {
  name: "interviewPage",
  components: {
    tour,
    loadingItem,
    startBtn,
  },
  data() {
    return {
      load: true,
   
    };
  },
  methods: {
    async verify() {
      try {
        this.load = true;
        const res = await axios.get(meetingInterview.verify, {
          params: {
            token: this.$route.query.token,
          },
          headers: {
            Authorization: "Bearer " + this.$route.query.token,
          },
        });
        const { companyName, fullname, info, consentStatement, position, positionId, question, languageData, isCandidateQuestion, constantSounds } = res.data.data;

        this.$store.commit("interviewQuestions", {
          constantSounds,
          questions: question,
          infoStartMesage: info,
          isCandidateQuestion: isCandidateQuestion,
          user: fullname,
          company: companyName,
          token: this.$route.query.token,
          position: position,
          positionId: positionId,
          consentStatement: consentStatement,
          isPreliminaryInterview: true,
          projectId: languageData?.projectId,
          language: {
            name: languageData?.name,
            code: languageData?.isoCode,
          }
        });
        

        this.$store.commit("checkConsentStatement", languageData?.projectId);
        this.load = false;
      } catch (error) {
        this.$router.push("/end");
      }
    },
    changeMicrophoneInteraction() {
      if (this.clickRelease) {
        // Handle click-release interaction
      }
      if (this.pressHold) {
        // Handle press-hold interaction
      }
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
  mounted() {
  
  },
  created() {
    if (this.$store.state.isPreliminaryInterview == false) {
      this.verify();
    } else this.load = !this.$store.state.isPreliminaryInterview;

    if (!this.$store.state.consentStatement.isValid && this.$route.name != "firstPage") {
      this.$router.push("/");
    }
    this.$i18n.locale = this.$route.params.lang;
    moment.locale(this.$route.params.lang)
  },
  computed: {
    steps(){
      return [
        {
          target: '[data-v-step="0"]',
          header: {
            title: this.$t("preInterview.firstStep.tourSteps.title"),
          },
          content: this.$t("preInterview.firstStep.tourSteps.content"),
          params: {
            placement: "top",
          },
        },
      ];
    },
    
  },
  watch: {
    "$store.state.consentStatement.isValid"(val) {
      if (!val && this.$route.name != "firstPage") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
.circle {
  border-radius: 50%;
  box-shadow: 0px 0px 3px 3px #0000001a;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.master {
  color: black;
  float: right;
}

@media (max-width: 900px) {
  .master {
    display: none;
  }
}
</style>
