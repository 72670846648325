<template>
  <div class="flex justify-center w-full min-h-screen-24">
    <div class="flex flex-col items-center justify-center">
      <img
        src="@/assets/interview/success.gif"
        alt="success"
        class="rounded-full max-w-[260px] h-auto"
      />
      <p
        class="mt-10 w-8/12 mx-auto text-gray-300 text-center font-semibold text-base"
      >
        {{ $t('endOfProcess.thankYouDateSelected') }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "success-date",
  created() {
    setTimeout(() => {
      this.$store.commit("setDateSelected", true);
      this.$store.commit("setInterviewDates", {
        interviewDates: [],
        token: "",
        consentStatementUrl: "",
        isPreliminaryInterview: false,
      });

      this.$router.push("/end");
    }, 3000);
  },
};
</script>
