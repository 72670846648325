<template>
    <div>
   
      <div  class="text-center" align="center">
        <svg xmlns="http://www.w3.org/2000/svg" class="svg-success" viewBox="0 0 24 24">
          <g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
            <circle class="success-circle-outline" cx="12" cy="12" r="11.5" />
            <circle class="success-circle-fill" cx="12" cy="12" r="11.5" />
            <polyline class="success-tick" points="17,8.5 9.5,15.5 7,13" />
          </g>
        </svg>
        <p class="w-8/12 mx-auto text-gray-300 text-sm max-md:text-xs max-sm:w-10/12 text-center">
          {{ $t('thanksPage.applicationReceived') }}
        </p>
      </div>
    </div>
  </template>
  <script>
  // import speechAnimation from "./components/speechAnimation.vue";
  export default {
    name: "thanksPage",
    
    components: {
      // speechAnimation,
    },
    data() {
      return {
    
  
      };
    },

  };
  </script>
  <style>
  .svg-success {
    display: inline-block;
    vertical-align: top;
    height: 220px;
    width: 220px;
    opacity: 1;
    overflow: visible;
  }
  
  @keyframes success-tick {
    0% {
      stroke-dashoffset: 16px;
      opacity: 1;
    }
  
    100% {
      stroke-dashoffset: 31px;
      opacity: 1;
    }
  }
  
  @keyframes success-circle-outline {
    0% {
      stroke-dashoffset: 72px;
      opacity: 1;
    }
  
    100% {
      stroke-dashoffset: 0px;
      opacity: 1;
    }
  }
  
  @keyframes success-circle-fill {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .success-tick {
    fill: none;
    stroke-width: 1px;
    stroke: #182451;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: -14px;
    animation: success-tick 450ms ease 1400ms forwards;
    opacity: 0;
  }
  
  .success-circle-outline {
    fill: none;
    stroke-width: 1px;
    stroke: #ffffff;
    stroke-dasharray: 72px, 72px;
    stroke-dashoffset: 72px;
    animation: success-circle-outline 300ms ease-in-out 800ms forwards;
    opacity: 0;
  }
  
  .success-circle-fill {
    fill: #ffffff;
    stroke: none;
    opacity: 0;
    animation: success-circle-fill 300ms ease-out 1100ms forwards;
  }
  </style>
  