<template>
  <div class=" transition-all ease-in-out">
    <div class="relative w-full h-full">
      <startBtn v-if="!load" />
      <loadingItem
        v-else-if="load"
        :text="$t('interviewDatePage.loading')"
      />
    </div>
  </div>
</template>

<script>
import startBtn from "./components/start.vue";
import loadingItem from "@/components/loading.vue";
import axios from "axios";
import { technicalInterviewMicrosoft } from "@/networking/urlmanager";
import moment from 'moment'

export default {
  name: "interviewPage",
  data() {
    return {
      load: false,
    };
  },
  components: {
    startBtn,
    loadingItem,
  },
  methods: {
    async verify() {
      try {
        this.load = true;
        const res = await axios.post(technicalInterviewMicrosoft.confirmMail,
          {},
          {
            headers: {
              Authorization: "Bearer " + this.$route.params.token,
            },
          }
        );

        this.load = false;

        const [firstDate, secondDate, thirdDate] = res.data.data.dates;
        this.$store.commit("setInterviewDates", {
          interview: res.data.data,
          interviewDates: [firstDate, secondDate, thirdDate],
          token: this.$route.params.token,
          interviewHistory: res.data.data.history,
          consentStatement: res.data.data.consentStatementUrl,
          isPreliminaryInterview: true,
        });
        console.log(this.$store.state.interviewHistory);

        this.load = false;
      } catch (error) {
        console.log(error);
        this.$router.push("/end");
      }
    },
  },
  created() {
    this.$i18n.locale = this.$route?.params?.lang || 'tr';
    moment.locale(this.$route?.params?.lang || 'tr');
    this.verify();
  },
};
</script>
