var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-5 border border-white w-[90%] sm:w-3/4 md:w-2/3 lg:w-5/12 xl:w-4/12 rounded-2xl flex flex-col space-y-4"},[_c('h1',{staticClass:"text-sm md:text-lg text-center text-white"},[_vm._v(" "+_vm._s(_vm.$t("preInterview.firstStep.micTypeModal.title"))+" ")]),_c('div',{staticClass:"flex flex-col sm:flex-row items-center justify-between space-x-0 sm:space-x-4 space-y-4 sm:space-y-0"},[_c('button',{staticClass:"px-4 py-1 md:py-2 text-[14px] md:text-base w-full rounded hover:border-[#005CC8] border border-white transition-colors duration-200",class:{
        '!bg-[#005CC8] text-white !border-[#005CC8]': _vm.selectedMicType === 1,
        ' text-white': _vm.selectedMicType !== 1,
      },on:{"click":function($event){return _vm.selectMicType(1)}}},[_vm._v(" "+_vm._s(_vm.$t("preInterview.firstStep.micTypeModal.pressRelease"))+" ")]),_c('button',{staticClass:"px-4 py-1 md:py-2 text-[14px] md:text-base w-full rounded hover:border-[#005CC8] border border-white transition-colors duration-200",class:{
        '!bg-[#005CC8] text-white !border-[#005CC8] ': _vm.selectedMicType === 2,
        ' text-white': _vm.selectedMicType !== 2,
      },on:{"click":function($event){return _vm.selectMicType(2)}}},[_vm._v(" "+_vm._s(_vm.$t("preInterview.firstStep.micTypeModal.byHolding"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }