<template>
  <div>
    <div class="flex flex-col items-center bg-gray-50 sm:rounded-3xl w-full my-5 max-sm:my-0">
      <div class="flex justify-between card-header p-5 bgcol w-full sm:rounded-t-2xl text-white">
        <h1 class="text-lg sm:text-xl">{{ $t('interviewDatePage.appointmentTitle') }}</h1>
      </div>
      <div class="w-full px-4 sm:px-10">
        <div class="p-5 text-center text-blue-800 font-bold">
          <h2 class="text-sm sm:text-md">
            {{ $t('interviewDatePage.selectDateTimeInstruction') }}
          </h2>

        </div>
        <div class="flex flex-col gap-2 text-sm mb-4 bg-gray-200 p-1 px-2 rounded-sm">
          <div class="flex items-center gap-2">
            <div
              class="bg-green-100 text-green-500 ring-2 w-4 h-4 rounded-sm ring-green-800/40 flex justify-center items-center">
              <i class=" fa fa-clock"> </i>

            </div>
            {{ $t('interviewDatePage.companyHours') }}

          </div>
          <div class="flex items-center gap-2">
            <div
              class="bg-red-100 text-red-500 ring-2 w-4 h-4 rounded-sm ring-red-800/40 flex justify-center items-center">
              <i class=" fa fa-clock"> </i>
            </div>
            <span>{{ $t('interviewDatePage.rejectedHours') }}</span>
          </div>

         

        </div>
        <div class="flex flex-col gap-2 text-sm mb-4 bg-gray-200 p-1 px-2 rounded-sm">

        <div class="flex items-center gap-2">
            <div
              class="bg-gray-100 text-gray-500 ring-2 w-4 h-4 rounded-sm ring-gray-800/40 flex justify-center items-center">
              <i class=" fa fa-info"> </i>
            </div>
            <span>{{ $t('interviewDatePage.counterProposal') }}</span>
          </div>
        </div>
        <div class="w-full">
          <calendar ref="calendarRef" :dates="processedDates" :disablePastDates="true" :isLoading="isLoading"
            :maxSelectedTimes="3" :maxSelectedTimesWarning="$t('interviewDatePage.maxDateLimit', ['3'])"
            @update:selectedDates="handleDateUpdate" @dates-initialized="handleDatesInitialized"
            :selectedTimes="selectedTimes" @month-changed="handleMonthChange" />

          <div class="w-full border border-gray-400 rounded my-5">
            <ul>
              <li v-if="selectedTimes.length === 0" class="text-center p-1">
                {{ $t('interviewDatePage.selectDate') }}
              </li>

              <li v-else class="grid grid-cols-3 p-1 border-b border-b-gray-400">
                <strong>{{ $t('interviewDatePage.date') }}</strong>
                <strong class="col-span-2">{{ $t('interviewDatePage.time') }}</strong>
              </li>

              <!-- isCandidateDates isCompanyDate -->
              <li v-for="(item, index) in selectedTimes" :key="index"
                class="grid grid-cols-3 p-1 border-b border-gray-400 items-center">
                <span>{{ formatDate(item.date) }}</span>
                <div class="flex overflow-x-auto scroll-design col-span-2">
                  <button v-for="timeSlot in getAvailableTimesForDate(item.date)" :key="timeSlot.time"
                    :disabled="timeSlot.isCandidateDates"
                    class="text-black border px-1 py-1 m-1 rounded-lg shadow-lg whitespace-nowrap"
                    @click="selectTime(item.date, timeSlot.time)" :class="{
            '!bg-blue-500 text-white': item.times?.includes(timeSlot.time),
            'bg-green-100 text-green-500 ring-2 ring-green-800/40': timeSlot.isCompanyDate && !item.times?.includes(timeSlot.time),
            'bg-red-100 text-red-500 ring-2 ring-red-800/40 cursor-not-allowed': timeSlot.isCandidateDates && !item.times?.includes(timeSlot.time),
            'hover:bg-blue-500/90 hover:text-white': !item.times?.includes(timeSlot.time) && !timeSlot.isCompanyDate
          }">
                    {{ timeSlot.time }}
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <div class="flex flex-col sm:flex-row items-center justify-between space-x-0 sm:space-x-3 my-5 w-full">
            <asyncButton text="Gönder" buttonClass="w-full text-sm text-white"
              containerClass="w-full flex items-center justify-center mt-2 sm:mt-0 rounded" :disabled="!canSubmit"
              :class="{
            'bg-gray-400': !canSubmit,
            'bg-blue-500 hover:bg-blue-500/90': canSubmit
          }" :load="confirmLoading" @click="handleSubmit" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import asyncButton from "@/components/button.vue";
import { technicalInterviewMicrosoft } from "@/networking/urlmanager";
import calendar from "./calendar.vue";

export default {
  name: "select-date-modal",
  components: { asyncButton, calendar },
  props: ["detail"],

  data() {
    moment.locale("tr");
    return {
      selectedTimes: [],
      availableDates: [],
      processedDates: [],
      isLoading: false,
      confirmLoading: false,
      timeSlots: this.generateTimeSlots("08:00", "18:00"),
      currentMonth: moment().month(),
      currentYear: moment().year()
    };
  },

  computed: {
    canSubmit() {
      return this.selectedTimes.length > 0 &&
        this.selectedTimes.some(date => date.times && date.times.length > 0);
    },

    storeDates() {
      return this.$store.state.interviewDates || [];
    }
  },

  watch: {
    storeDates: {
      handler(newDates) {
        if (newDates?.length) {
          this.updateAvailableDates();
        }
      },
      immediate: true
    }
  },

  methods: {
    generateTimeSlots(startTime, endTime) {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");
      const slots = [];

      while (start <= end) {
        const slotStart = start.format("HH:mm");
        start.add(1, "hour");
        const slotEnd = start.format("HH:mm");
        slots.push(`${slotStart} / ${slotEnd}`);
      }

      return slots;
    },

    formatDate(date) {
      return moment(date).format("LL");
    },

    isTimeDisabled(date, time) {
      const [startTime] = time.split(" / ");
      const dateTime = moment(`${date} ${startTime}`, "YYYY-MM-DD HH:mm");
      return dateTime.isBefore(moment());
    },

    getTimeButtonClasses(item, time) {
      const [startTime] = time.split(" / ");
      const dateTime = moment(`${item.date} ${startTime}`);
      const isProposed = this.storeDates.some(proposedDate => { return moment(proposedDate).isSame(dateTime); });

      const isPastTime = this.isTimeDisabled(item.date, time);
      const isSelected = item.times && item.times.includes(time);

      return {
        '!bg-blue-500 text-white': isSelected,
        'bg-green-100 text-green-500 ring-2 ring-green-800/40': isProposed && !isSelected,
        'bg-red-100 text-red-500': isPastTime,
        'hover:bg-blue-500/90 hover:text-white': !isSelected && !isPastTime,
        'opacity-50 cursor-not-allowed': isPastTime
      };
    },

    handleDateUpdate(newDates) {
      this.selectedTimes = newDates;
    },

    handleDatesInitialized(dates) {
      this.processedDates = dates;
    },

    handleMonthChange(month, year) {
      this.currentMonth = month;
      this.currentYear = year;
      this.updateAvailableDates();
    },

    getAvailableTimesForDate(date) {
      const now = moment();
      const isToday = moment(date).isSame(now, 'day');

      // Şirketin kabul ettiği (isCompany=true) saatler
      const companyDates = this.$store.state.interviewHistory
        ?.flatMap(item =>
          item.dates
            .filter(dateObj =>
              moment(dateObj.date).format('YYYY-MM-DD') === date &&
              item.isCompany
            )
            .map(dateObj => moment(dateObj.date).format('HH:mm'))
        ) || [];

      // Adayın önerdiği (isCompany=false) saatler  
      const candidateDates = this.$store.state.interviewHistory
        ?.flatMap(item =>
          item.dates
            .filter(dateObj =>
              moment(dateObj.date).format('YYYY-MM-DD') === date &&
              !item.isCompany
            )
            .map(dateObj => moment(dateObj.date).format('HH:mm'))
        ) || [];

      // Store'dan gelen şirket tarihleri
      const storeDates = this.storeDates
        ?.map(date => moment(date))
        .filter(dateObj => dateObj.format('YYYY-MM-DD') === date)
        .map(dateObj => dateObj.format('HH:mm')) || [];

      // Şirketin yeni önerdiği saatler (interviewDates'ten gelenler)
      const proposedDates = this.$store.state.interviewDates
        ?.map(date => moment(date))
        .filter(dateObj => dateObj.format('YYYY-MM-DD') === date)
        .map(dateObj => dateObj.format('HH:mm')) || [];

      return this.timeSlots
        .map(timeSlot => {
          const [startTime] = timeSlot.split(' / ');

          // Eğer bugünse ve saat geçmişse gösterme
          if (isToday) {
            const timeDate = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm');
            if (timeDate.isBefore(now)) {
              return null;
            }
          }

          return {
            time: timeSlot,
            isCompanyDate: companyDates.includes(startTime) || storeDates.includes(startTime),
            isCandidateDates: candidateDates.includes(startTime),
            isProposed: proposedDates.includes(startTime)
          };
        })
        .filter(item => item !== null);
    },

    checkCompanyTime(date, time) {
      const [startTime] = time.split(' / ');
      const timeSlot = this.getAvailableTimesForDate(date);
      return timeSlot.find(t => t.time.startsWith(startTime))?.isCompanyDate ||
        timeSlot.find(t => t.time.startsWith(startTime))?.isProposed;
    },

    // CALENDAR.VUE METHODS

    // Şirketin gönderdiği görüşme tarihlerini kontrol eder
    isInterviewDate(day) {
      const formattedDates = this.$store.state.interviewDates.map((date) => {
        return parseInt(moment(date).format("DD"), 10);
      });

      // Günleri karşılaştırıyoruz.
      return formattedDates.some((d) => d === day.day);
    },

    // Şirketin TRUE olan tarihlerini kontrol eder (kabul edilmiş tarihler)
    isCompanyTrue(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      }).format("YYYY-MM-DD");

      return this.$store.state.interviewHistory.some((item) =>
        item.dates.some(
          (dateObj) =>
            moment(dateObj.date).format("YYYY-MM-DD") === date &&
            item.isCompany
        )
      );
    },

    // Şirketin FALSE olan tarihlerini kontrol eder (reddedilmiş tarihler)
    isCompanyFalse(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      }).format("YYYY-MM-DD");

      return this.$store.state.interviewHistory.some((item) =>
        item.dates.some(
          (dateObj) =>
            moment(dateObj.date).format("YYYY-MM-DD") === date &&
            !item.isCompany
        )
      );
    },
    // Mevcut tarihleri günceller
    updateAvailableDates() {
      const now = moment();
      const filteredDates = this.storeDates.filter(date => {
        const dateObj = moment(date);
        return dateObj.month() === this.currentMonth &&
          dateObj.year() === this.currentYear &&
          dateObj.isSameOrAfter(now.startOf('day'));
      });

      this.processedDates = filteredDates.map(date => {
        const utcDate = moment(date);
        return {
          date: utcDate.format("YYYY-MM-DD"),
          times: [utcDate.format("HH:mm")],
          isProposed: true
        };
      });
    },

    // Saat seçimi yapıldığında çalışır
    selectTime(date, time) {
      // Geçmiş zaman kontrolü
      if (this.isTimeDisabled(date, time)) {
        return;
      }

      // Zaman detaylarını al
      const timeDetails = this.getAvailableTimesForDate(date).find(t => t.time === time);
      const isCompanyTime = timeDetails?.isCompanyDate || timeDetails?.isProposed;

      // Eğer daha önce seçilmiş şirket saati varsa ve normal saat seçmeye çalışıyorsa engelle
      const hasCompanySelection = this.selectedTimes.some(selectedDate => {
        return selectedDate.times.some(selectedTime => {
          const selectedTimeDetails = this.getAvailableTimesForDate(selectedDate.date).find(t => t.time === selectedTime);
          return selectedTimeDetails?.isCompanyDate || selectedTimeDetails?.isProposed;
        });
      });

      if (hasCompanySelection && !isCompanyTime) {
        this.warningMesage(this.$t('interviewDatePage.cannotSelectMultiple'));
        return;
      }

      const maxHours = isCompanyTime ? 1 : 3;
      let selectedDate = this.selectedTimes.find(item => item.date === date);
      const totalTimes = this.selectedTimes.reduce((sum, d) => sum + d.times.length, 0);

      // Eğer yeni bir tarihse ve limit aşılmamışsa ekle
      if (!selectedDate && this.selectedTimes.length < maxHours) {
        selectedDate = { date, times: [] };
        this.selectedTimes.push(selectedDate);
      }

      // Eğer tarih bulunamadıysa ve limit aşıldıysa uyarı ver
      if (!selectedDate) {
        this.warningMesage(this.$t('interviewDatePage.maxDateLimit', [maxHours]));
        return;
      }

      const timeIndex = selectedDate.times.indexOf(time);
      if (timeIndex > -1) {
        // Seçimi kaldır
        selectedDate.times.splice(timeIndex, 1);
        if (selectedDate.times.length === 0) {
          const dateIndex = this.selectedTimes.indexOf(selectedDate);
          this.selectedTimes.splice(dateIndex, 1);
        }
      } else {
        // Yeni zaman seç
        if (isCompanyTime) {
          // Şirket saati seçiliyorsa önceki tüm seçimleri temizle
          this.selectedTimes.length = 0;
          selectedDate = { date, times: [time] };
          this.selectedTimes.push(selectedDate);
        } else {
          // Eğer normal saat seçiliyorsa ve önceden şirket saati seçilmişse engelle
          if (hasCompanySelection) {
            this.warningMesage(this.$t('interviewDatePage.cannotSelectMultiple'));
            return;
          }
          // Normal saat seçimi için limit kontrolü
          if (totalTimes >= maxHours) {
            this.warningMesage(this.$t('interviewDatePage.maxHoursLimit', [maxHours]));
            return;
          }
          selectedDate.times.push(time);
        }
      }
    },
    async handleSubmit() {
      if (!this.canSubmit) return;

      this.confirmLoading = true;
      try {
        const formattedTimes = this.selectedTimes.flatMap(({ date, times }) =>
          times.map(time => {
            const [startTime] = time.split(" / ");
            return moment.tz(`${date} ${startTime}`, "YYYY-MM-DD HH:mm", "Europe/Istanbul")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss[Z]");
          })
        );

        await this.sendRequest(technicalInterviewMicrosoft.suggestNewMeetDate, {
          stateId: 4,
          selectedDate: formattedTimes
        });

        this.$emit("isOk", true);
      } catch (error) {
        console.error(error);
        this.authController(error);
      } finally {
        this.confirmLoading = false;
      }
    },

    async sendRequest(url, body) {
      return this.axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`
        }
      });
    }
  },

  mounted() {
    this.$refs.calendarRef?.initializeAvailableDates();
  },

  beforeDestroy() {
    this.selectedTimes = [];
    this.processedDates = [];
  }
};
</script>

<style scoped>
.scroll-design::-webkit-scrollbar {
  height: 5px;
}

.scroll-design::-webkit-scrollbar-thumb {
  background: #182451;
  border-radius: 10px;
}

.scroll-design::-webkit-scrollbar-track {
  background: transparent;
}

.bgcol {
  background-color: #182451;
}
</style>