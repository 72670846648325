<template>
  <div>
   
    <div
      v-if="view"
      class="bg-black fixed h-screen top-0 w-full bg-opacity-30 z-50 left-0"
    >
    
      <button
        @click="close"
        class="text-xl md:absolute md:bg-white max-sm:text-white flex justify-end right-3 px-4 py-2 rounded cursor-pointer top-3 max-sm:bgcol max-sm:w-full max-sm:rounded-none max-sm:border-b max-sm:border-gray-300"
      >
        <i class="fas fa-times max-sm:shadow-xl"></i>
      </button>
      <div
        class="h-full mx-auto rounded-xl text-white w-8/12 max-sm:w-full flex justify-center items-center "
      >
        <div v-if="file.type == 1" class="overflow-y-auto">
          <img :src="file.fileUrl" alt="Selected File" class="rounded" />
        </div>
        <div v-else-if="file.type == 4" class="overflow-y-auto h-full w-full">
          <iframe
            :src="file.fileUrl"
            frameborder="0"
            class="rounded h-full w-full"
          ></iframe>
        </div>
        <div v-else class="overflow-y-auto w-full h-full">
          <div v-for="(page, index) in pdfPages" :key="index">
            <img :src="page" alt="PDF Page" style="width: 100%" class="pb-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { candidate } from "@/networking/urlmanager";
import { GlobalWorkerOptions } from "pdfjs-dist/build/pdf";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

export default {
  props: ["selectFile"],
  data() {
    return {
      file: "",
      view: false,
      pdfPages: [],
    };
  },
  methods: {
    close() {
      this.file = "";
      this.view = false;
    },
    show() {
      this.file = "";
      this.pdfPages = [];
      this.view = true;
      setTimeout(() => {
        this.viewFile();
      }, 50);
    },
    async viewFile() {
      if(this.selectFile && this.selectFile.file && this.selectFile.fileName && this.selectFile.fileType == 2){
        const fileExtension = await this.selectFile.fileName
          .split(".")
          .pop()
          .toLowerCase();
        const type = await this.getFileType(fileExtension);
        if (type == 2) {
          this.file = {
             fileUrl: this.selectFile.file,
            type: 4,
          };
          const data = await this.readFileUrl(this.selectFile.fileName);
          await this.renderPDF(data);
        } else {
          this.file = {
             fileUrl: this.selectFile.file,
            type: 1,
          };
        }
        return;
      }
      if (this.selectFile.fileType == 2) {
        const fileUrl = await this.readFileUrl(this.selectFile.file);

        const storageUrl = fileUrl.data.data;
        const urlParts = new URL(storageUrl);
        const filePath = urlParts.pathname.substr(1);
        const fileName = filePath.split(".").pop().toLowerCase();
        const type = await this.getFileType(fileName);
        let url = Array.isArray(fileUrl.data.data)
          ? fileUrl.data.data[0]
          : fileUrl.data.data;

        if (type == 2) {
          this.file = {
            fileUrl: url,
            type: 4,
          };
        } else {
          this.file = {
            fileUrl: url,
            type: 1,
          };
        }
      } else {
        const fileExtension = await this.selectFile.file.name
          .split(".")
          .pop()
          .toLowerCase();
        const type = await this.getFileType(fileExtension);

        if (type == 2) {
          const data = await this.readFile(this.selectFile.file);
          await this.renderPDF(data);
        } else {
          let fileUrl = await this.generateURL(this.selectFile.file);
          this.file = {
            fileUrl: fileUrl,
            type: 1,
          };
        }
      }

    },
    getFileType(fileExtension) {
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const pdfExtensions = ["pdf"];

      if (imageExtensions.includes(fileExtension)) return 1;
      else if (pdfExtensions.includes(fileExtension)) return 2;
      else return 3;
    },
    generateURL(file) {
      if (file) {
        let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc);
        }, 1000);
        return fileSrc;
      }
      return "";
    },
    readFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = atob(e.target.result.replace(/.*base64,/, ""));
          resolve(data);
        };
        reader.readAsDataURL(file);
      });
    },
    async readFileUrl(fileUrl) {
      try {
        const response = await this.axios.post(candidate.readFile, {
          fileUrl: fileUrl,
        });
        return response;
      } catch (err) {
        alert(err);
      }
    },
    async renderPDF() {
      // hatalı
      // console.log(data)
      // const pdf = await getDocument({ data }).promise;
      
      // for (let i = 1; i <= pdf.numPages; i++) {
      //   const page = await pdf.getPage(i);
      //   const viewport = page.getViewport({ scale: 2 });
      //   const canvas = document.createElement("canvas");
      //   const canvasContext = canvas.getContext("2d", {
      //     willReadFrequently: true,
      //   });
      //   canvas.height = viewport.height;
      //   canvas.width = viewport.width;
      //   await page.render({ canvasContext, viewport }).promise;
      //   const imageData = canvas.toDataURL("image/png");
      //   this.pdfPages.push(imageData);
      //   await this.$nextTick();
      // }
    },
  },
};
</script>
