const baseUrl = window.ENV?.VUE_APP_BASE_URL || process.env.VUE_APP_BASE_URL || "http://localhost";

export const meetingInterview = {
  mail: baseUrl + "/candidate/interview/mail",
  getMail: baseUrl + "/candidate/interview/get/mail/all",
  verify: baseUrl + "/candidate/interview/verify",
  converter: baseUrl + "/candidate/interview/converter",
  questionConverter: baseUrl + "/candidate/interview/question/converter",
  complateMeeting: baseUrl + "/candidate/interview/complate/meeting",
  candidateQuesiton: baseUrl + "/candidate/interview/candidate/question",
  textToSpeech: baseUrl + "/candidate/interview/text/to/question",
};

export const technicalInterviewMicrosoft = {
  confirmMail:
    baseUrl + "/candidate/technical/interview/microsoft/confirm/mail",
  createEvent:
    baseUrl + "/candidate/technical/interview/microsoft/create/event",
  // eski end point
  // suggestMeetDate: baseUrl + "/candidate/technical/interview/microsoft/suggest/meet/date",
  suggestNewMeetDate:
    baseUrl + "/candidate/technical/interview/microsoft/suggest/new/date",
};

export const candidate = {
  verify: baseUrl + "/candidate/personel/information/verify",
  add: baseUrl + "/candidate/personel/information/add-new",
  getFileList: baseUrl + "/candidate/personel/information/get/personal-documents/list",
  getPersonalInformationTypes: baseUrl + "/setting/general/get/personal-document-type/all",
  readFile: baseUrl + "/candidate/personel/information/read/file",
  allFilesDone: baseUrl + "/candidate/personel/information/is-completed",
};

export const reference = {
  verify: baseUrl + "/candidate/evaluation/verify",
  getReferences: baseUrl + "/candidate/evaluation/get-all",
  submitReferences: baseUrl + "/candidate/evaluation/add",
}