<template>
  <div class="w-full flex items-center justify-center">
    <div v-show="!isConsentStatement">
      <verifyConsentStatement ref="refConsentStatement" @change="(r) => (isConsentStatement = r)" :load="load" />
    </div>

    <viewFile ref="viewFile" :selectFile="selectFile" />
    <div v-if="isConsentStatement" class="w-full overflow-y-auto h-full">
      <div class="w-9/12 p-2 mx-auto max-lg:w-11/12 max-sm:w-full">
        <h2 class="text-center text-2xl font-semibold text-white pb-3 pt-4">
          {{ $t('personalInformation.title') }}
        </h2>
        <div v-if="allLoad" class="flex justify-center items-center h-full text-white">
            <i class="fas fa-spinner fa-spin text-4xl animate-spin"></i>
          </div>
        <form @submit.prevent="add">
         
          <div v-for="(item, index) in list" :key="index">
            <card :item="item" :load="load" :fileNames="fileNames"
              @fileUpload="(r) => ((item.file = r), (item.fileType = 1))"
              @selectFile="() => ((selectFile = item), $refs.viewFile.show())" @fileName="addFileName" />
          </div>

          <div class="flex justify-end gap-2">
            <buttonItem :disabled="disabledButton" :load="saveLoad" :text="isSave ? $t('personalInformation.submit') : $t('personalInformation.update')" :buttonClass="[
      'text-white !mt-5 px-5',
      disabledButton ? 'bg-blue-500/50' : 'w-full bg-blue-500 ',
    ]" />
            <buttonItem type="button" :load="saveLoad" :text="$t('personalInformation.complete')" @click="allFilesDone"  v-if="isAllFilesDone"
            :buttonClass="[
      'text-white !mt-5 px-5 w-full bg-blue-500']"
            />
          </div>
        </form>
      </div>
      <div class="mt-5 flex flex-col items-center">
        <div class="w-10/12 mx-auto h-[1px]" :style="footerStyle"></div>
        <span v-html="$t('personalInformation.note')" class="text-white/50 text-sm justify-center mt-4"></span>
      </div>
    </div>
  </div>
</template>
<script>
import card from "./components/card.vue";
import viewFile from "./components/viewFile.vue";
import verifyConsentStatement from "./components/verifyConsentStatement.vue";
import axios from "axios";
import { candidate } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";
import moment from "moment/moment";

export default {
  name: "personel-information",
  components: {
    card,
    viewFile,
    buttonItem,
    verifyConsentStatement,
  },
  data() {
    return {
      selectFile: "",
      allLoad: false,
      load: false,
      saveLoad: false,
      isConsentStatement: false,
      refreshLoad: false,
      footerStyle:
        "background: rgb(7, 18, 56); background: linear-gradient(90deg, rgba(7, 18, 56, 1) 0%, rgba(224, 224, 224, 1) 50%, rgba(7, 18, 56, 1) 100%);",
      list: [
      ],
      fileNames: [],
      isAllFilesDone: false,
    };
  },
  computed: {
    disabledButton() {
      let data = this.list.filter((r) => {
        return r.fileType === 1 && r.file !== "";
      });
      return data.length > 0 ? false : true;
    },
    isSave() {
      let data = this.list.filter((r) => {
        return r.fileType === 2 && r.file != "";
      });
      return data.length > 0 ? false : true;
    },
  },
  methods: {
    async verify() {
      try {
        this.allLoad = true;
        const token = await this.$route.params.token;
        const result = await axios.post(
          candidate.verify,
          {},
          {
            headers: {
              Authorization: "Bareer " + token,
            },
          }
        );

        let data = result.data.data;

        if (data.isConsentStatement == 2) {
          this.$refs.refConsentStatement.setCheckbox(false);
          this.isConsentStatement = false;
        }
        this.$store.commit("interviewQuestions", {
          token: this.$route.params.token,
          consentStatement: data.consentStatementFile,
        });
        await this.getAll();
      } catch (error) {
        console.log(error);
        this.$router.push("/end");
      } finally {
        this.allLoad = false;
      }
    },
    async add() {
      this.saveLoad = true;
      const formData = new FormData();

      this.list.forEach(async (item) => {
        if (item.file && item.fileType == 1) {
          formData.append("files", item.file);
        }

      });
      const typeIds = this.list.filter((r) => r.fileType == 1).map((r) => r.id);
      formData.append("typeIds", JSON.stringify(typeIds));
      const updateIds = this.list.filter((r) => r.fileType == 1).map((r) =>
        r.documentId ? r.documentId : null
      );
      formData.append("updateIds", JSON.stringify(updateIds));
      // const formData = new FormData();
      // files.forEach((file, index) => {
      //   formData.append('files', file);
      // });
      // formData.append('documents', JSON.stringify([
      //   { typeId: 1 },
      //   { typeId: 2 },
      //   // ... one for each file in the same order
      // ]));
      try {
        const response = await this.axios.post(candidate.add, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        await this.getAll();
        await this.successMessage(response.data.message);
        this.saveLoad = false;
      } catch (error) {
        this.authController(error);
        this.saveLoad = false;
      }
    },
    addFileName(fileName) {
      this.fileNames.push(fileName);
    },
    async getAll() {
      try {
        this.load = true;
        let response = await this.axios.get(candidate.getPersonalInformationTypes);
        this.list = response?.data?.data?.map((item) => {
          return {
            id: item.id,
            title: item.title,
            description: item.description,
            file: "",
            fileType: null,
          };
        });
        if (this.list.length > 0) {
          await this.getFiles();
        }
        this.load = false;
      } catch (err) {
        this.load = false;
        console.log(err);
        this.authController(err);
      }
    },

    async getFiles() {
      try {
        this.load = true;
        let response = await this.axios.get(candidate.getFileList);
        this.list = this.list.map((item) => {
          let file = response.data.data.find((r) => r.typeId == item.id);
          if (file) {
            item.file = file.fileUrl;
            item.fileName = file.fileName;
            item.fileType = 2;
            item.documentId = file.documentId;
          }
          return item;
        });

        // Check if all items in this.list have corresponding files
        this.isAllFilesDone = this.list.every(item =>
          response.data.data.some(file => file.typeId == item.id)
        );
        this.load = false;
      } catch (err) {
        this.load = false;
        console.log(err);
        this.authController(err);
      }
    },

    async allFilesDone() {
      try {
        this.saveLoad = true;
        let response = await this.axios.get(candidate.allFilesDone);
        await this.successMessage(response.data.message);
        this.$router.push("/thank-you");
        this.saveLoad = false;
      } catch (err) {
        this.saveLoad = false;
        console.log(err);
        this.authController(err);
      }
    },
  },

  created() {
    this.$i18n.locale = this.$route.params.lang || 'tr';
    moment.locale(this.$route.params.lang || 'tr')
    if (this.$store.state.isPreliminaryInterview == false) {
      this.verify();
    } else {
      this.getAll();
    }

  
    // if (!this.$store.state.consentStatement.isValid && this.$route.path != "/") {
    //   this.isConsentStatement = true;
    // }
  },
};
</script>
