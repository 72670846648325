<template>
  <div class="w-full flex flex-col space-y-6">
    <!-- Text To Speak -->

    <!-- Speak To Text -->
    <soundQuestion v-if="!isCandidate" ref="soundQuestion" @isError="(val) => errorValue(val)"
      @expectedAnswerState="(val) => changeCandidate(val)" @load="(val) => (load = val)" :questionState="1"
      :questionType="4" :expectedAnswer="1" @notVoice="val => notVoice(val)" />

    <candidateAnswer v-if="isCandidate" ref="soundQuestion" @changeAnswer="(val) => changeSpeakText(val)"
      @noSoundDetected="(val) => noSoundQuestion(val)" @load="(val) => (load = val)" />
    <!-- Speak To Text -->

    <!-- Header Content -->
    <div class="w-full rounded-full flex items-center justify-center">
      <!-- SORU SORMAYA BAŞLADIĞI KISISM  -->
      <asistan :gifActive="gifActive" />
    </div>
    <!-- <speechAnimation :botSepeak="botSepeak" :speechReady="speechReady" /> -->

    <!-- Text To Speak -->
    <textToItem :value="getText" ref="textItem" @startValue="startValue" @endValue="endValue"
      @setLoading="(val) => (speechReady = val)" />

    <div class="w-full flex justify-center items-center">
      <p class="w-8/12 mx-auto text-gray-400 text-[15px] max-md:text-xs max-sm:w-10/12 text-center">
        {{ getText.name ? formatText(getText).name : getText.text ? formatText(getText.text) : formatText(getText) }}
      </p>
    </div>

    <!-- Header Content -->
  </div>
</template>

<script>
import textToItem from "../../components/textToItem.vue";
import candidateAnswer from "./candidateAnswer.vue";
import soundQuestion from "../../components/soundQuestion.vue";
import asistan from "@/components/asistans.vue";

export default {
  name: "content-panel",
  props: [""],
  components: {
    textToItem,
    candidateAnswer,
    soundQuestion,
    asistan,
  },
  data() {
    return {
      gifActive: false,
      speakText: "",
      isCandidate: false,
      erorState: false,
      botSepeak: false,
      load: false,
      isMobileDevice: window.innerWidth <= 768,
      isSoundPlaying: false,
      speechReady: false,
      isLanguageError: false,
    };
  },
  methods: {
    resetButton() {
      this.$emit("resetStep", true);
    },
    startValue() {
      this.gifActive = true;
      this.imgtActive = false;
      this.botSepeak = true;
      this.$emit("isDisabled", true);
    },
    errorValue(val) {
      this.erorState = val.erorState;
      this.isLanguageError = val.isLanguageError;
      if (val.erorState) {
        this.synthesizeSpeech();
        this.resetButton();
      }
    },
    endValue() {
      this.gifActive = false;
      this.imgtActive = true;
      this.botSepeak = false;
      this.$emit("isDisabled", false);

      setTimeout(() => {
        this.checkComplatedCandidate();
      }, 100);
    },
    startRecording() {
      this.$refs.soundQuestion.startRecording();
    },
    stopRecording() {
      this.$refs.soundQuestion.stopRecording();
    },
    notVoice(val) {
      this.voiceNotUnderstood = true
      this.erorState = true
      this.notificationAlert(this.$t("preInterview.fourStepAssistan.index.answerIsNotUnderstood"), "error");
      if (val) {
        this.synthesizeSpeech()
      }
    },
    synthesizeSpeech() {
      setTimeout(() => {
        this.$refs.textItem.synthesizeSpeech();
      }, 50);
    },
    changeSpeakText(val) {
      if (val) {
        this.speakText = val;
        this.synthesizeSpeech();
        this.resetButton();
        this.$store.commit("setQuestionCount");
      }
    },
    noSoundQuestion(val) {
      if(val?.isLanguageError){
        this.speakText = {
          text: this.$t("preInterview.thirdStepInterview.content.languageError"),
        };
      }else{
        this.speakText = {
          name: this.$store.state?.constantSounds?.listeningToYou?.text,
          url: this.$store.state?.constantSounds?.listeningToYou?.url,
        };
      }
      //"Sizi dinliyorum.";
      this.synthesizeSpeech();
      this.resetButton();
    },
    changeCandidate(val) {
      if (val.expectedAnswerState == false) {
        this.$router.push("/complate/answer/list");
        this.$store.commit("setCandidateQuestion", 3);
      } else {
        this.isCandidate = true;
        this.speakText = {
          name: this.$store.state?.constantSounds?.listeningToYou?.text,
          url: this.$store.state?.constantSounds?.listeningToYou?.url,
        };
        this.$store.commit("setCandidateQuestion", 2);
        this.resetButton();
        this.synthesizeSpeech();
      }
    },
    checkComplatedCandidate() {
      let questionCount = this.$store.state.candidate.questionCount;
      if (
        !this.$store.state.candidate.isEndQuestion &&
        questionCount == (this.$store.state.isDecision ? 3 : 5)
      ) {
        this.$store.commit("setCandidateQuestion", 3);
        this.$router.push("/complate/answer/list");
      }
    },
  },
  created() {
    this.isCandidate = this.$store.state.candidate.isQuestion == 2;
    this.checkComplatedCandidate();
  },
  computed: {
    getText() {
      // If there's a language error, return language error message
      if (this.isLanguageError) {
        return {
          name: this.$store.state?.isCandidateQuestion?.text,
          text: this.$t("preInterview.thirdStepInterview.content.languageError")
        };
      }

      // If there's an error state but no language error
      if (this.erorState) {
        return {
          name: this.$store.state?.isCandidateQuestion?.text,
          url: this.$store.state?.constantSounds?.vagueAnswer?.url
        };
      }

      // No errors - handle normal flow
      if (this.$store.state.candidate.isQuestion == 1) {
        return {
          ...this.$store.state.isCandidateQuestion,
          name: this.$store.state.isCandidateQuestion.text
        };
      }

      if (this.speakText) {
        return this.speakText;
      }

      // Default case
      return {
        name: this.$store.state?.constantSounds?.listeningToYou?.text,
        url: this.$store.state?.constantSounds?.listeningToYou?.url
      };
    }
  },
  watch: {
    load(val) {
      this.$emit("isLoad", val);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
