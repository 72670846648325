<template>
  <div>
    <div class="min-h-screen-24 w-full flex flex-col items-center justify-center space-y-6 overflow-y-auto">
      <contentPanel
        @nextStep="() => nextQuestion()"
        @resetStep="(val) => resetStep(val)"
        @changeAnswer="(val) => (speakText = val)"
        @changeExpectedAnswer="(val) => (changeExpectedAnswer = val)"
        ref="contentPanel"
        @isDisabled="(val) => (isDisabled = val)"
      />

      <buttonStep
        ref="proccessBtn"
        :disabled="isDisabled"
        @isRecord="(val) => recordSound(val)"
      />
    </div>

    <!-- <tour
      v-if="this.$store.state.answerPage === 0"
      :steps="steps && !isDisabled"
    /> -->
  </div>
</template>

<script>
// import tour from "@/components/tour.vue";

import contentPanel from "./components/content.vue";
import buttonStep from "@/components/recordButton.vue";

export default {
  name: "third-step",
  components: {
    // tour,
    contentPanel,
    buttonStep,
  },
  data() {
    return {
      speakText: "",
      changeExpectedAnswer: "",
      stepLoad: false,
      isDisabled: true,
      steps: [
      {
          target: '[data-v-step="0"]',
          header: {
            title: this.$t("preInterview.thirdStepInterview.toureSteps.question.title"),
          },
          content: this.$t("preInterview.thirdStepInterview.toureSteps.question.content"),
          params: {
            placement: "bottom",
          },
        },
        {
          target: '[data-v-step="1"]',
          header: {
            title: this.$t("preInterview.thirdStepInterview.toureSteps.stop.title"),
          },
          content: this.$t("preInterview.thirdStepInterview.toureSteps.stop.content"),
          params: {
            placement: "bottom",
          },
        },
        {
          target: '[data-v-step="2"]',
          header: {
            title: this.$t("preInterview.thirdStepInterview.toureSteps.nextQuestion.title"),
          },
          content: this.$t("preInterview.thirdStepInterview.toureSteps.nextQuestion.content"),
          params: {
            placement: "bottom",
          },
        },
      ],
    };
  },
  methods: {
    nextQuestion() {
      // is last question
      const questionsAndAnswerList =
        this.$store.state.questionsAndAnswer.length;
      const page = this.$store.state.answerPage + 1;

      if (page >= (page + 2 <= questionsAndAnswerList ? this.$store.state.isDecision ? questionsAndAnswerList - 2 : questionsAndAnswerList : questionsAndAnswerList)) {
        this.$store.commit("interviewAnswer", {
          answer: this.speakText,
          expectedAnswer: this.changeExpectedAnswer,
          questionId: this.$store.state.answerPage,
        });

        this.$router.push("/candidate/question");
      } else {
        this.$store.commit("interviewAnswer", {
          answer: this.speakText,
          expectedAnswer: this.changeExpectedAnswer,
          questionId: this.$store.state.answerPage,
        });

        this.resetStep();
        this.$refs.contentPanel.synthesizeSpeech();
      }
    },
    recordSound(val) {
      if (val) this.$refs.contentPanel.startRecording();
      else this.$refs.contentPanel.stopRecording();
    },
    resetStep(val = true) {
      this.isDisabled = val;
      this.$refs.proccessBtn.reset();
    },
  },
  created() {
    if (!this.$store.state.consentStatement.isValid && this.$route.path != "/") {
      this.$router.push("/");
    }
    if (this.isMobile() && this.$store.state.mobileState.question) {
      this.$swal({
        title: this.$t("preInterview.secondStepStart.isMobile.title"),
        text: this.$t("preInterview.secondStepStart.isMobile.text"),
        icon: "question",
        confirmButtonColor: "#3085d6",
        confirmButtonText: this.$t("preInterview.secondStepStart.isMobile.confirmButtonText"),
      });
    } else {
      this.$store.commit("mobileQuestion", true);
    }

    if (this.$store.state.isAnswer == 2) {
      this.$router.push("/candidate/question");
    }
  },
  watch: {
    stepLoad() {
      if (this.buttonStep == 2 && this.load == false) {
        this.buttonStep += 1;
      }
    },
  },
};
</script>
