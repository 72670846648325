<template>
  <div class="w-full flex justify-center">
    <div class="w-9/12 p-2 mx-auto max-lg:w-11/12 max-sm:w-full">
      <h2 class="text-center text-2xl font-semibold text-white pb-3 pt-4">
        {{ $t('references.title') }}
      </h2>
      <p class="text-center text-white/80 mb-6">
        {{ $t('references.description') }}
      </p>

      <!-- Initial loading state during verification -->
      <div v-if="verifying" class="flex flex-col justify-center items-center h-full text-white">
        <i class="fas fa-spinner fa-spin text-4xl animate-spin mb-4"></i>
        <p>{{ $t('general.loading') }}</p>
      </div>

      <!-- Verification error -->
      <div v-else-if="verificationError" class="bg-red-500/10 text-red-400 p-4 rounded-lg text-center">
        <p class="text-xl font-bold mb-2">{{ $t('general.warning') }}</p>
        <p>{{ verificationError }}</p>
      </div>

      <!-- Consent Statement Section -->
      <div v-else-if="!isConsentApproved" class=" p-6 rounded-lg">
        
    
 

        <div class="w-full">
      <button
        data-v-step="0"
        @click="approveConsent"
        :disabled="!consentChecked"
        class="h-44 w-44 animation-pulse bg-white circle pulse textcol text-xl flex items-center justify-center mx-auto"
        :class="{ 'opacity-50 cursor-not-allowed animate-none': !consentChecked }"
        >
        {{ $t('references.start') }}
      </button>

      <div class="flex items-center justify-center pt-5 text-xs gap-3">
        <input
          v-model="consentChecked"
          type="checkbox"
          class="w-5 h-5 cursor-pointer"
        />
        <a
          class="text-white underline"
          :href="$store.state.consentStatement.url"
          target="_blank"
          >{{ $t('references.consentApproval') }}</a
        >
      </div>
    </div>
        
        <div v-if="consentError" class="text-red-400 text-sm mb-4">
          {{ consentError }}
        </div>
      
      </div>

      <!-- References Form (after verification and consent) -->
      <div v-else>
        <div v-if="loading" class="flex justify-center items-center h-full text-white">
          <i class="fas fa-spinner fa-spin text-4xl animate-spin"></i>
        </div>

        <div v-else-if="error" class="bg-red-500/10 text-red-400 p-4 rounded-lg">
          {{ error }}
        </div>

        <div v-else>
          <!-- Progress Indicator -->
          <div class="flex justify-center items-center mb-4">
            <span class="bg-blue-500/20 text-white px-4 py-2 rounded-lg">
              {{ $t('references.reference') }} {{ currentRefIndex + 1 }} {{ $t('references.of') }} {{ referenceCount }}
            </span>
          </div>

          <!-- Current Reference -->
          <div v-if="references.length > 0" class="mb-6 bg-gray-800/40 p-6 rounded-lg">
            <h3 class="text-white text-xl mb-4 font-bold">{{ $t('references.reference') }} {{ currentRefIndex + 1 }}</h3>
            
            <div class="grid grid-cols-2 gap-4">
              <div v-for="(field, fieldIndex) in inputFields" :key="fieldIndex" class="flex flex-col space-y-2">
                <label :for="`ref-${currentRefIndex}-${field.id}`" class="text-white font-semibold">
                  {{ field.label }} <span class="text-red-400">*</span>
                </label>
                <input 
                  :id="`ref-${currentRefIndex}-${field.id}`" 
                  type="text"
                  v-model="references[currentRefIndex][field.id].value"
                  :placeholder="getPlaceholder()"
                  required
                  :class="[
                    'bg-gray-700/80 border rounded-lg p-3 text-white outline-none focus:ring-2 focus:ring-blue-500',
                    fieldErrors[field.id] ? 'border-red-500' : 'border-gray-600'
                  ]"
                />
                <span v-if="fieldErrors[field.id]" class="text-red-400 text-sm">
                  {{ $t('references.fieldRequired') }}
                </span>
              </div>
            </div>
            
            <div v-if="formError" class="mt-4 text-red-400 text-sm p-2 bg-red-500/10 rounded">
              {{ formError }}
            </div>
          </div>

          <!-- Navigation Buttons -->
          <div class="flex justify-between mt-4">
            <button 
              @click="prevReference" 
              :disabled="currentRefIndex === 0"
              class="bg-gray-600 hover:bg-gray-700 disabled:bg-gray-600/50 text-white px-6 py-2 rounded-lg"
            >
              {{ $t('references.previous') }}
            </button>
            
            <button 
              v-if="currentRefIndex < referenceCount - 1" 
              @click="validateAndNext" 
              class="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg"
            >
              {{ $t('references.next') }}
            </button>
            
            <button 
              v-else
              @click="validateAndSubmit" 
              :disabled="loading"
              class="bg-green-500 hover:bg-green-600 disabled:bg-green-500/50 text-white px-6 py-2 rounded-lg"
            >
              {{ loading ? $t('references.submitting') : $t('references.submit') }}
            </button>
          </div>

          <!-- Step Indicators -->
          <div class="flex justify-center gap-2 mt-6">
            <span 
              v-for="index in referenceCount" 
              :key="index"
              :class="[
                'w-3 h-3 rounded-full', 
                currentRefIndex === index - 1 
                  ? 'bg-blue-500' 
                  : isReferenceComplete(index - 1) 
                    ? 'bg-green-500' 
                    : 'bg-gray-500'
              ]"
              @click="goToReference(index - 1)"
            ></span>
          </div>
        </div>

        <!-- Footer -->
        <div class="mt-8 flex flex-col items-center">
          <div class="w-10/12 mx-auto h-[1px] bg-gradient-to-r from-gray-800 via-gray-400 to-gray-800"></div>
          <span class="text-white/50 text-sm justify-center mt-4">{{ $t('references.allFieldsRequired') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { reference } from '@/networking/urlmanager';

export default {
  name: "ReferencePage",
  data() {
    return {
      // Verification and consent states
      verifying: true,
      verificationError: null,
      isConsentApproved: false,
      consentChecked: false,
      consentError: null,
      token: null,
      
      // Form data
      inputFields: [],
      references: [],
      loading: false,
      error: null,
      referenceCount: 5, // Default value, will be overridden by backend response
      currentRefIndex: 0,
      fieldErrors: {},
      formError: null,
      cvId: null,
      projectId: null,
    };
  },
  created() {
    this.$i18n.locale = this.$route.params.lang || 'tr';
    this.token = this.$route.params.token;
    this.verifyToken();
    

  
    // if (!this.$store.state.consentStatement.isValid && this.$route.path != "/") {
    //   this.isConsentStatement = true;
    // }
  },
  methods: {
    async verifyToken() {
      this.verifying = true;
      this.verificationError = null;
      
      try {
        const result = await axios.post(reference.verify, {}, {
          headers: {
            Authorization: "Bearer " + this.token
          }
        });
        
        const data = result.data.data || {};
        this.cvId = data.cvId || null;
        this.projectId = data.languageData.projectId || null;
        // Store consent statement URL
        this.$store.state.consentStatement.url = data.consentStatementFile || null;

       

        this.verifying = false;
      } catch (error) {
        console.error('Token verification error:', error);
        this.verificationError = this.$t('references.verificationError');
        this.verifying = false;
      }
    },
    
    approveConsent() {
      this.consentError = null;
      
      if (!this.consentChecked) {
        this.consentError = this.$t('references.pleaseApproveConsent');
        return;
      }
      
      this.isConsentApproved = true;
      this.fetchInputFields();
    },
    
    getPlaceholder() {
      return this.$t('references.placeholder');
    },
    
    async fetchInputFields() {
      this.loading = true;
      try {
        const response = await axios.get(reference.getReferences, {
          headers: {
            Authorization: "Bearer " + this.token
          }
        });
        
        // Get data from the response format you provided
        const inputFieldsData = response.data.data.result || [];
        const referenceCountFromBackend = response.data.data.referenceCount || this.referenceCount;
        
        // Map the fields using the new format
        this.inputFields = inputFieldsData.map(field => {
          const label = this.$i18n.locale === 'en' ? field.en_value : field.value;
          return {
            id: field.id,
            label: label,
            type: 'text',
            required: true
          };
        });

        // Update reference count from backend
        this.referenceCount = referenceCountFromBackend;
        
        // Initialize references based on count from backend
        this.initializeReferences();
        
        this.loading = false;
      } catch (error) {
        console.error('Error setting up input fields:', error);
        this.error = this.$t('references.errorMessage');
        this.loading = false;
      }
    },
    
    initializeReferences() {
      this.references = [];
      
      // Create references based on the count from the backend
      for (let i = 0; i < this.referenceCount; i++) {
        const newReference = {};
        
        // Initialize all fields with typeId and empty value
        this.inputFields.forEach(field => {
          newReference[field.id] = {
            typeId: field.id,
            value: ''
          };
        });
        
        this.references.push(newReference);
      }

      // Start with the first reference
      this.currentRefIndex = 0;
    },

    nextReference() {
      if (this.currentRefIndex < this.referenceCount - 1) {
        this.currentRefIndex++;
      }
    },

    prevReference() {
      if (this.currentRefIndex > 0) {
        this.currentRefIndex--;
      }
    },

    goToReference(index) {
      if (index >= 0 && index < this.referenceCount) {
        this.currentRefIndex = index;
      }
    },

    isReferenceComplete(index) {
      if (!this.references[index]) return false;
      
      // Check if ALL fields in this reference are filled
      return this.inputFields.every(field => 
        this.references[index][field.id] && 
        this.references[index][field.id].value && 
        this.references[index][field.id].value.trim() !== ''
      );
    },
    
    validateCurrentReference() {
      this.fieldErrors = {};
      this.formError = null;
      
      const currentRef = this.references[this.currentRefIndex];
      let isValid = true;
      
      // Check each field in current reference
      this.inputFields.forEach(field => {
        if (!currentRef[field.id] || !currentRef[field.id].value || currentRef[field.id].value.trim() === '') {
          this.fieldErrors[field.id] = this.$t('references.fieldRequired');
          isValid = false;
        }
      });
      
      if (!isValid) {
        this.formError = this.$t('references.fillAllRequiredFields');
      }
      
      return isValid;
    },
    
    validateAllReferences() {
      // Reset error state
      this.fieldErrors = {};
      this.formError = null;
      
      let allValid = true;
      
      // Check all references for completion
      for (let i = 0; i < this.references.length; i++) {
        const ref = this.references[i];
        
        // Check each field in the reference
        for (const field of this.inputFields) {
          if (!ref[field.id] || !ref[field.id].value || ref[field.id].value.trim() === '') {
            allValid = false;
            // If on the current reference, show the field errors
            if (i === this.currentRefIndex) {
              this.fieldErrors[field.id] = this.$t('references.fieldRequired');
            }
          }
        }
      }
      
      if (!allValid) {
        this.formError = this.$t('references.allReferencesMustBeComplete');
        
        // If we're not on an incomplete reference, move to the first incomplete one
        for (let i = 0; i < this.references.length; i++) {
          const ref = this.references[i];
          const isComplete = this.inputFields.every(field => 
            ref[field.id] && ref[field.id].value && ref[field.id].value.trim() !== ''
          );
          
          if (!isComplete) {
            this.currentRefIndex = i;
            break;
          }
        }
      }
      
      return allValid;
    },
    
    validateAndNext() {
      if (this.validateCurrentReference()) {
        this.nextReference();
      }
    },
    
    validateAndSubmit() {
      if (this.validateAllReferences()) {
        this.submitForm();
      }
    },
    
    async submitForm() {
      this.loading = true;
      try {
        // Create an array to hold each reference object
        const formattedReferences = [];
        
        // Format each reference with the required structure
        this.references.forEach(reference => {
          // Convert the reference object values to the required format
          const values = Object.values(reference).map(item => ({
            cvId: this.cvId,
            projectId: this.projectId,
            typeId: item.typeId,
            value: item.value
          }));
          
          // Add this reference to the array
          formattedReferences.push({
            values: values
          });
        });
        
        
        // Submit using the correct endpoint
        await axios.post(reference.submitReferences, 
          { list: formattedReferences },
          {
            headers: {
              Authorization: "Bearer " + this.token
            }
          }
        );
        
        this.$router.push("/thank-you");
      } catch (error) {
        console.log(error);
        this.authController(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
/* All styles removed since we're using Tailwind */
</style>