<template>
  <div class="w-full flex justify-center items-center h-full">
    <div class="w-full">
      <button
        data-v-step="0"
        @click="selectDate"
        class="h-44 w-44 animation-pulse bg-white circle pulse textcol text-xl flex items-center justify-center mx-auto"
      >
        {{ $t('interviewStart.selectDate') }}
      </button>

      <div class="flex items-center justify-center pt-5 text-xs gap-3">
        <input
          v-model="$store.state.consentStatement.isValid"
          type="checkbox"
          class="w-5 h-5 cursor-pointer"
          @change="chanceIsValid"
        />
        <a
          class="text-white underline"
          :href="$store.state.consentStatement.url"
          target="_blank"
          >{{ $t('interviewStart.consentApproval') }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "start-btn",
  data() {
    return {
      isValid: this.$store.state.consentStatement.isValid,
    };
  },
  methods: {
    selectDate() {
      if (this.$store.state.consentStatement.isValid) {
        this.$router.push("/interview/setup/pick-date");
      } else {
        this.authController(this.$t('interviewStart.pleaseApproveConsent'));
      }
    },
    chanceIsValid() {
      this.isValid = !this.isValid;
      this.$store.commit("chanceIsValid", this.isValid);
    },
  },
  created() {
    this.$store.commit("setConsentStatement", {
      isValid: true,
    });
    this.isValid = true;
    this.$router.push("/interview/setup/pick-date");
  },
};
</script>

<style>
.circle {
  border-radius: 50%;
  box-shadow: 0px 0px 3px 3px #0000001a;
}
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
