<template>
  <div class="w-full flex justify-center items-center h-full">
    <div class="w-full">
      <button
        data-v-step="0"
        @click="start"
        class="h-44 w-44 animation-pulse bg-white circle gap-1 pulse textcol text-xl flex items-center justify-center mx-auto"
      >
        <i v-if="load" class="fa-solid fa-spinner animate-spin"></i>
        {{ !load ? $t('general.continue') : $t('general.loading') }}
      </button>

      <div
        v-if="!load"
        class="flex items-center justify-center pt-10 text-sm gap-3"
      >
        <input
          v-model="$store.state.consentStatement.isValid"
          type="checkbox"
          class="w-5 h-5 cursor-pointer"
          @change="chanceIsValid"
        />
        <a
          class="text-white underline"
          :href="$store.state.consentStatement.url"
          target="_blank"
          >
          {{ $t('consentStatement.consentStatement') }}
          </a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "start-btn",
  props: ["load"],
  data() {
    return {
      isValid: false,
    };
  },
  methods: {
    start() {
      if (this.$store.state.consentStatement.isValid) {
        this.$emit("change", true);
      } else {
        
        this.authController("Lütfen Açık Rıza Beyanı Onaylayın.");
      }
    },
    chanceIsValid() {
      this.isValid = !this.isValid;
      this.$store.commit("chanceIsValid", this.isValid);
    },
    setCheckbox(val) {
      this.isValid = val;
      this.$store.commit("chanceIsValid", val);
    },
  },
  mounted() {
    this.isValid = this.$store.state.consentStatement.isValid;
  },
};
</script>

<style>
.circle {
  border-radius: 50%;
  box-shadow: 0px 0px 3px 3px #0000001a;
}
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
