<template>
  <div class="flex justify-center w-full min-h-screen-24">
    <div class="flex flex-col items-center justify-center space-y-4">
      <img
        :src="getImage"
        :alt="getText"
        class="rounded-full max-w-[260px] h-auto"
      />
      <p
        class=" w-8/12 mx-auto text-gray-300 text-center font-semibold text-base"
      >
        {{ getText }}
      </p>
      <p v-if="isProcessDone" class="text-xl text-gray-50">
        {{ $t('endOfProcess.processComplete') }}
      </p>
      <p v-else class="text-base text-gray-50">
        {{ $t('endOfProcess.tryAgainLater') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "end-of-process",
  data() {
    return {
      fail: false,
    };
  },
  computed: {
    dateSelected() {
      return this.$store.state.dateSelected;
    },
    interviewDone() {
      return this.$store.state.interviewDone;
    },
    getImage() {
      if (this.dateSelected) {
        return require("@/assets/interview/success.gif");
      } else if (this.interviewDone) {
        return require("@/assets/interview/success.gif");
      } else {
        return require("@/assets/interview/failure.gif");
      }
    },
    getText() {
      if (this.dateSelected) {
        return this.$t('endOfProcess.thankYouDateSelected');
      } else if (this.interviewDone) {
        return this.$t('endOfProcess.interviewCompleted');
      } else {
        return this.$t('endOfProcess.errorOccurred');
      }
    },
    isProcessDone() {
      return this.dateSelected || this.interviewDone;
    },
  },
};
</script>
