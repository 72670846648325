<template>
  <div
    class="p-5 border border-white w-[90%] sm:w-3/4 md:w-2/3 lg:w-5/12 xl:w-4/12 rounded-2xl flex flex-col space-y-4">
    <h1 class="text-sm md:text-lg text-center text-white">
      {{ $t("preInterview.firstStep.micTypeModal.title") }}
    </h1>
    <div class="flex flex-col sm:flex-row items-center justify-between space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
      <button @click="selectMicType(1)"
        class="px-4 py-1 md:py-2 text-[14px] md:text-base w-full rounded  hover:border-[#005CC8]  border border-white transition-colors duration-200"
        :class="{
          '!bg-[#005CC8] text-white !border-[#005CC8]': selectedMicType === 1,
          ' text-white': selectedMicType !== 1,
        }">
        {{ $t("preInterview.firstStep.micTypeModal.pressRelease") }}
      </button>
      <button @click="selectMicType(2)"
        class="px-4 py-1 md:py-2 text-[14px] md:text-base w-full rounded  hover:border-[#005CC8]  border border-white transition-colors duration-200"
        :class="{
          '!bg-[#005CC8] text-white !border-[#005CC8] ': selectedMicType === 2,
          ' text-white': selectedMicType !== 2,
        }">
        {{ $t("preInterview.firstStep.micTypeModal.byHolding") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "mic-type-modal",
  data() {
    return {
      selectedMicType: 1,
    };
  },
  methods: {
    selectMicType(type) {
      this.selectedMicType = type;
      this.$emit("result", this.selectedMicType);
    },
  },
  mounted() {
    this.$emit("result", this.selectedMicType);
  },
};
</script>
